import React, { Component } from "react";
import "../styles/home.scss";
import Loading from "./common/Loading";
import Popup from "./common/Popup";
import Select from "react-select";
// import { DatePicker, Space } from "antd";
import SuccessIcon from "../icons/SuccessIcon";
import ErrorIcon from "../icons/ErrorIcon";
import ReactEcharts from "echarts-for-react";
import echarts from "echarts";

import cookie from "react-cookies";
import { setAPIAuthorizationToken } from "../apis/API";
import * as GetChartAPI from "../apis/GetChartAPI";
import moment from "moment";


import { DatePicker } from "@douyinfe/semi-ui";
// const { RangePicker } = DatePicker;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      option: {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["Success", "Fail"],
          right: "2%",
          top: "10%",
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [],
            name: "Success",
            type: "line",
            itemStyle: { color: "#68e365" },
          },
          {
            data: [],
            name: "Fail",
            type: "line",
            itemStyle: { color: "#f79292" },
          },
        ],
      },
      starttime: "",
      endtime: "",
      successCount: 0,
      failCount: 0,
      userName: "",
      selectTime: [],
      dates: [
        moment().subtract(1, "months").format("YYYY-MM-DD"), // 上个月
        moment().format("YYYY-MM-DD"),
      ],
    };
    this.handleRangeChange = this.handleRangeChange.bind(this);
  }

  onChange(date, dateString) {
    console.log(date, dateString);
  }

  componentDidMount() {
    let param = cookie.load("token");
    this.setState({ loadingShow: true });
    setAPIAuthorizationToken(param.data);
    let { starttime, endtime, dates } = this.state;
    [starttime, endtime] = dates;
    this.setState(
      {
        starttime,
        endtime,
      },
      () => {
        this.getChart();
      }
    );
  }

  // 筛选时间
  handleRangeChange(dates) {
    if (dates && dates.length === 2) {
      const starttime = dates[0]; // 获取开始时间戳（秒）
      const endtime = dates[1]; // 获取结束时间戳（秒）
      console.log(starttime, endtime);
      this.setState({ dates, starttime, endtime }, () => {
        this.getChart();
      });
    } else {
      this.setState({ starttime: null, endtime: null });
    }
  }

  getChart() {
    this.setState({ loadingShow: true });
    GetChartAPI.getChart(
      this.state.userName,
      this.state.starttime,
      this.state.endtime
    ).then((res) => {
      console.log(res);
      var code = res.data.code;
      if (code === 200) {
        var data = res.data.data;
        console.log(data);
        this.setState({
          successCount: data.es[1].count,
          failCount: data.es[0].count,
        });
        let { option } = this.state;
        let xData1 = [];
        let xData2 = [];
        let yData1 = [];
        let yData2 = [];
        data.list_1.map((item) => {
          xData1.push(item.sendTime);
          yData1.push(item.count);
        });
        data.list_2.map((item) => {
          xData2.push(item.sendTime);
          yData2.push(item.count);
        });
        console.log(option);
        this.setState(
          (prevState) => {
            const newOption = { ...prevState.option }; // 复制整个option对象
            // 更新X轴数据
            newOption.xAxis.data = [...xData1]; // 用展开操作符来创建新的数组
            // 确保更新正确的系列数据，假设您的图表有一个系列
            newOption.series = [
              {
                ...prevState.option.series[0], // 复制原有的系列配置
                data: [...yData1], // 更新数据
              },
              {
                ...prevState.option.series[1], // 复制原有的系列配置
                data: [...yData2], // 更新数据
              },
            ];
            console.log(newOption);
            return { option: newOption };
          },
          () => {
            this.setState({ loadingShow: false });
            // 这个回调确保状态已经更新后执行，可以在这里进行后续操作，比如重新加载图表等
            console.log("Option state updated!");
          }
        );
      }
    });
  }

  render() {
    const { option, dates, successCount, failCount } = this.state;
    return (
      <React.Fragment>
        <Loading loadingShow={this.state.loadingShow} />
        <div className="home-content">
          <div className="total-row">
            <div className="right">
              {/* SUCCESS */}
              <div className=" card success">
                <div className="after"></div>
                <div className="icon">
                  <SuccessIcon size={35} className="icon-suc" />
                </div>
                <div className="txt-box">
                  <h2>{successCount}</h2>
                  <p>Success</p>
                </div>
              </div>
              {/* ERROR */}
              <div className="fail card">
                <div className="after"></div>
                <div className="icon">
                  <ErrorIcon size={35} className="icon-suc" />
                </div>
                <div className="txt-box">
                  <h2>{failCount}</h2>
                  <p>Fail</p>
                </div>
              </div>
            </div>
            <div className="left">
              {/* <Select className="select" options={selectArr} /> */}
              <DatePicker
                onChange={this.handleRangeChange}
                className="datePicker"
                type="dateRange"
                value={dates}
                density="compact"
              />
            </div>
          </div>
          <div className="chart-row">
            <div className="chart-content">
              <div className="chart-box">
                <ReactEcharts
                  option={option}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;

